<template>
    <v-card class="mx-auto" color="white">
        <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
                <p class="card-title mb-0">{{ title }}</p>
            </div>            
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
            <slot name="chart"> </slot>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },        
    }
}
</script>
