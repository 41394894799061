<template>
  <v-layout>
    <DashboardAppBar />

    <v-container fluid class="px-1">
      <v-row dense align="start" justify="center" class="mx-1">
        <v-col cols="12" sm="10" md="6" xl="4">
          <v-card>
            <v-card-text class="text--primary">
              <div class="d-flex flex-column align-center justify-center">
                <span class="ma-3">Ein neues WWS ist in Arbeit: </span>
                <v-progress-circular :rotate="360" :size="100" :width="15" :value="totalProgress" color="primary">
                  {{ totalProgress + "%" }}
                </v-progress-circular>
              </div>
            </v-card-text>
            <v-card-text>
              <div class="d-flex flex-column align-center justify-center">
                <v-btn small @click="open = !open"
                  ><span> {{ open ? "Meilensteine ausblenden" : "Meilensteine einblenden" }}</span></v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="10" md="6" xl="4">
          <v-card>
            <v-card-text>
              <v-list dense disabled>
                <v-list-item-group v-for="(key, value, index) in milestones" :key="`key${value}${index}`">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title v-text="value" class="font-weight-bold"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-progress-linear :value="calculateProgressForMilestone(key)" color="primary" height="20" width="50"> </v-progress-linear>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-expand-transition>
                    <div v-show="open" class="pb-3 mb-1">
                      <v-list-item v-for="(v, index) in key" :key="index">
                        <v-list-item-content>
                          <v-list-item-title class="text-body-2">{{ v.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon :color="v.status ? 'green' : 'red'"> {{ v.status ? "mdi-check" : "mdi-close" }} </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </v-expand-transition>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app inset height="69" elevation="1">
      <v-col cols="12" class="pa-0">
        <v-row dense align="center" justify="start" class="px-1">
          <v-spacer></v-spacer>
          <v-col cols="auto"> </v-col>
        </v-row>
      </v-col>
    </v-footer>
  </v-layout>
</template>

<script>
import DashboardAppBar from "../../components/appbars/DashboardAppBar.vue";
import ChartCard from "../../components/charts/ChartCard.vue";
import _ from "lodash";

export default {
  components: {
    DashboardAppBar,
    ChartCard,
  },

  mounted() {
    // this.initialize();
  },

  data() {
    return {
      // itemsByDepartment: [],
      open: false,
      progressPercentage: null,
      milestones: {
        Dashboard: [
          { name: "Dashboard", status: false },
          { name: "Diagramme und Übersichten", status: false },
        ],
        Artikel: [
          { name: "Artikelliste", status: true },
          { name: "Artikelerstellung", status: true },
          { name: "Artikelansicht", status: false },
          { name: "Artikeländerungen", status: true },
        ],
        Hersteller: [
          { name: "Herstellerliste", status: true },
          { name: "Herstellererstellung", status: true },
          { name: "Herstelleransicht", status: false },
          { name: "Herstelleränderungen", status: true },
        ],
        Events: [
          { name: "Eventliste", status: true },
          { name: "Eventerstellung", status: false },
          { name: "Eventansicht", status: false },
          { name: "Eventänderungen", status: false },
        ],
        Kunden: [
          { name: "Kundenliste", status: true },
          { name: "Kundenerstellung", status: false },
          { name: "Kundenansicht", status: false },
          { name: "Kundenänderungen", status: false },
        ],
        Kalender: [
          { name: "Kalender", status: false },
          { name: "Terminerstellung", status: false },
          { name: "Terminansicht", status: false },
          { name: "Terminänderungen", status: false },
        ],
        Bestellvormerkungen: [
          { name: "Vormerkungsliste", status: false },
          { name: "Vormerkungserstellung", status: false },
          { name: "Vormerkungsansicht", status: false },
          { name: "Vormerkungsänderungen", status: false },
        ],
        Bestellungen: [
          { name: "Bestellliste", status: false },
          { name: "Bestellerstellung", status: false },
          { name: "Bestellansicht", status: false },
          { name: "Bestelländerungen", status: false },
        ],
      },
    };
  },

  computed: {
    totalNumOfMilestones() {
      var totalnumofmilestones = 0;
      _.forEach(this.milestones, (key, value) => {
        totalnumofmilestones = totalnumofmilestones + key.length;
      });
      return totalnumofmilestones;
    },

    totalNumOfFinishedMilestones() {
      var numoffinishedmilestones = 0;
      _.forEach(this.milestones, (key, value) => {
        _.forEach(key, (k) => {
          if (k.status === true) {
            numoffinishedmilestones++;
          }
        });
      });
      return numoffinishedmilestones;
    },

    totalProgress() {
      return parseFloat((this.totalNumOfFinishedMilestones / this.totalNumOfMilestones) * 100).toFixed(2);
    },
  },

  methods: {
    // async initialize() {
    //   const response = await fetch(`/api/dashboard/charts/init`);
    //   const json = await response.json();

    //   for (let [key, value] of Object.entries(json)) {
    //     this[key] = value;
    //   }
    // },

    calculateProgressForMilestone(milestone) {
      var numofsteps = milestone.length;
      var numoffinishedsteps = 0;
      _.forEach(milestone, (step) => {
        if (step.status === true) {
          numoffinishedsteps++;
        }
      });

      const progress = (numoffinishedsteps / numofsteps) * 100;

      return progress;
    },
  },
};
</script>
