<template>
  <v-app-bar app height="124" class="align-start elevation-1">
    <v-col cols="12" class="py-0">
      <v-row dense class="flex-nowrap" align="center">
        <v-col cols="auto" class="pa-0">
          <v-row dense align="center" class="flex-nowrap">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 50px"></div>
          </v-col>
            <v-col cols="auto">
              <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer> 
        <v-col cols="auto" class="pa-0">
          <v-row dense align="center" justify="center" class="flex-wrap"
            ><v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                color="green lighten-1 grey--text text--lighten-4"
                to="/wws/database/articles/dataset/create"
              >
                <v-icon>mdi-tshirt-crew-outline</v-icon>
                <span class="ml-1 d-none d-md-flex">Artikel</span>
                <span class="d-none d-lg-flex">&nbsp;erstellen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <v-btn
                color="green lighten-1 grey--text text--lighten-4"
                to="/wws/appointments/table/dataset/create"
              >
                <v-icon>mdi-calendar-plus</v-icon>
                <span class="ml-1 d-none d-md-flex">Termin</span>
                <span class="d-none d-lg-flex">&nbsp;erstellen</span>
              </v-btn>
            </v-col>
            <v-col v-if="checkCurrentUser" cols="auto" class="ml-auto">
              <v-btn
                color="orange lighten-1 grey--text text--lighten-4"
                to="/wws/orders/ordered/dataset/create"
              >
                <v-icon>mdi-cart-plus</v-icon>
                <span class="ml-1 d-none d-md-flex">Vor-Order</span>
                <span class="d-none d-lg-flex">&nbsp;erstellen</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-app-bar>
</template>

<script>
export default {  
  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    }, 
  }
}
</script>